import React, { useMemo, useState, useEffect } from 'react';
import { Badge, Col, Row, Form, Table } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import Intent from '../../services/Intent';
import { generateIntentUrls } from '../../data/intents.data';
import IntentModal from '../../components/custom-modal/IntentModal';

import './TrackInvitations.scss';

const TrackInvitations = ({ userData, referralData, apiError }) => {
    const processedInvitationCode = `https://bakingsodalabs.com/?referral=${userData?.referralCode || ''}`;
    const [isCopied, setIsCopied] = useState(false);
    const [intents, setIntents] = useState({});
    const [showIntentModal, setShowIntentModal] = useState(false);
    const [intentContent, setIntentContent] = useState();

    const handleShowIntentModal = (content) => {
        setShowIntentModal(true);
        setIntentContent(content);
    }

    const handleCloseIntentModal = () => setShowIntentModal(false);

    useEffect(() => {
      (async () => {
          const randomIntents = await Intent.getIntentData();
          
          if (randomIntents?.data) {
              setIntents(randomIntents.data);
          }
      })()
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    }, [isCopied]);

    const statistics = useMemo(() => {
        if (!userData) {
            return [
                {
                    title: 'Invited Friends',
                    amount: '0'
                },
                {
                    title: 'Registered Friends',
                    amount: '0'
                },
                {
                    title: 'Earned Tokens',
                    amount: '0'
                },
            ];
        }

        return [
            {
                title: 'Invited Friends',
                amount: userData?.invitedFriends || referralData?.length || '0'
            },
            {
                title: 'Registered Friends',
                amount: userData?.whitelistedFriends || '0'
            },
            {
                title: 'Earned Tokens',
                amount: userData?.tokensEarned || '0'
            },
        ];
    }, [userData, referralData]);

    const headlineDOM = useMemo(() => {
        return <Col xs={12} className='trackInvitations mb-3'>
            <h3 className='h5'>Invite friends using your Unique Invitation Code</h3>
        </Col>
    }, []);

    const limitedTimeOfferDOM = useMemo(() => {
        return <Col xs={12} className='limitedTimeOffer'>
            <Badge>
                <i className='icon icon-stopWatch'/>
                Limited Time Offer
            </Badge>

            <p className='darkText mt-3 mb-0'>You will earn <span className='underline'>20 DGNB tokens</span> per friend that you invite.</p>

            <Row>
                <Col xs={12} md={6}>
                    <div className='inputWrapper'>
                        <Form.Control
                            value={processedInvitationCode}
                            readOnly
                        />
                        <div className='copyClipboardWrapper'>
                            <CopyToClipboard 
                                text={processedInvitationCode}
                                onCopy={() => setIsCopied(true)}
                            >
                                <i className={`icon ${!isCopied ? 'icon-clipboard' : 'icon-checkmark'}`}/>
                            </CopyToClipboard>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} className='mt-4 mt-md-0'>
                    <p className='shareTheLink'>Share the link on social media</p>
                    <div className='d-flex flex-row align-items-center socialMediaWrapper mt-4 mt-md-0'>
                        <a href={generateIntentUrls(intents?.referral?.replace('[REFERRAL_LINK]', processedInvitationCode), 'X')} rel="noreferrer noopener" target="blank">
                            <i className='icon icon-twitter'/>
                        </a>
                        <a href='#' onClick={() => handleShowIntentModal(generateIntentUrls(intents?.referral?.replace('[REFERRAL_LINK]', processedInvitationCode), 'TikTok'))}>
                            <i className='icon icon-tiktok'/>
                        </a>
                        <a href='#' onClick={() => handleShowIntentModal(generateIntentUrls(intents?.referral?.replace('[REFERRAL_LINK]', processedInvitationCode), 'Instagram'))}>
                            <i className='icon icon-instagram'/>
                        </a>
                        <a href={generateIntentUrls(intents?.referral?.replace('[REFERRAL_LINK]', processedInvitationCode), 'Warpcast')} rel="noreferrer noopener" target="blank">
                            <i className='icon icon-warpcast'/>
                        </a>
                    </div>
                </Col>
            </Row>
        </Col>
    }, [isCopied, intents]);

    const statisticsDOM = useMemo(() => {
        return statistics.map((stat, index) => {
            return <Col xs={12} md={4} key={index} className='statisticsColWrapper'>
                <div className='statisticsCol'>
                    <p className='h2 p-0'>{stat.amount}</p>
                    <p>{stat.title}</p>
                </div>
            </Col>
        });
    }, [statistics]);

    const tbodyDOM = useMemo(() => {
        const contentLoaderDom = () => {            
            const contentProps = { height: '20' }
            const rectProps = { width: `${Math.floor(Math.random() * 20) + 50}%`, x: '0', rx: '5', ry: '5', height: '15' };

            return <ContentLoader
                speed={2}
                backgroundColor='#f3f3f3'
                foregroundColor="#ecebeb"
                {...contentProps}
                style={{ width: '100%' }}
            >
                {
                    <rect y={'0'} {...rectProps}/>
                }
            </ContentLoader>
        }

        if (referralData?.length === 0 && apiError === 'noError') {
            return Array(Math.floor(Math.random() * 3) + 2).fill('').map((item, index) => {
                return <tr key={index}>
                    <td>{contentLoaderDom()}</td>
                    <td>{contentLoaderDom()}</td>
                    <td>{contentLoaderDom()}</td>
                </tr>
            })
        }

        if (referralData?.length === 0) {
            return <tr>
                <td colSpan={'100%'}>No users registered using your referral code yet.</td>
            </tr>
        }

        return <>
            {
                referralData?.map((user, index) => {
                    return <tr key={index}>
                        <td>{user?.email}</td>
                        <td>{user?.currentTime ? moment(user.currentTime).format('MM/DD/YYYY hh:mm A') : ''}</td>
                        <td>{user?.referralStatus === 'invited' ? <Badge className='pendingWhitelist'>Invited</Badge> : <Badge className='whitelisted'>Confirmed</Badge>}</td>
                    </tr>
                })
            }
        </>
    }, [referralData, apiError]);

    const tableDOM = useMemo(() => {
        return <Table className='mt-5 tableWrapper' responsive>
            <thead>
                <tr>
                    <th>Email Address</th>
                    <th>Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {tbodyDOM}
            </tbody>
        </Table>
    }, [referralData, tbodyDOM, apiError]);

    return <section className='trackInvitationsWrapper'>
        {headlineDOM}
        {limitedTimeOfferDOM}
        <Row className='mt-5'>
            {statisticsDOM}
        </Row>
        {tableDOM}

        <IntentModal
            show={showIntentModal}
            handleClose={handleCloseIntentModal}
            text={intentContent}
        />
    </section>;
}

export default TrackInvitations;