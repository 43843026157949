import React, { forwardRef, useImperativeHandle, useRef } from "react";

import "./Video.scss";

const Video = forwardRef(({ videoId }, ref) => {
  const videoRef = useRef(null);

  useImperativeHandle(ref, () => ({
    pauseVideo() {
      if (videoRef.current) {
        const iframe = videoRef.current;
        iframe.contentWindow.postMessage(
          JSON.stringify({ event: "command", func: "pauseVideo", args: [] }),
          "*"
        );
      }
    },
  }));

  return (
    <div className="video-container">
      <iframe
        ref={videoRef} // Attach the ref here
        src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <div className="video-overlay"></div> {/* Add gradient overlay */}
    </div>
  );
});

export default Video;
