import React, { useCallback, useContext, useMemo, useState } from "react";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import { WHITELIST_FIELDS } from "../../components/custom-modal/CustomModal";
import Input from "../../components/input/Input";
import { WhitelistRequirements } from "../../components/custom-modal/WhitelistRequirementsModal";
import { NotificationContext } from "../../components/notification/Notification";
import Authenticator from "../../services/Authenticator";
import { UPDATE_PASSWORD_FIELDS } from "../settings/Settings";
import Validator from "../../services/validator/Validator";

import "./TokenBoost.scss";

const TokenBoost = ({ userData }) => {
  const { notify } = useContext(NotificationContext);

  const [fieldErrors, setFieldErrors] = useState({
    emailAddress: [],
    currentPassword: [],
    newPassword: [],
  });
  const [fieldData, setFieldData] = useState({
    emailAddress: userData?.emailAddress,
    currentPassword: "",
    newPassword: "",
  });

  const onChange = useCallback((evt) => {
    const value = evt.target.value;
    const currentInput = UPDATE_PASSWORD_FIELDS.filter(
      (field) => field.name === evt.target.name
    )[0];
    const currentInputErrors = currentInput?.validation(value);

    setFieldErrors((prevState) => ({
      ...prevState,
      [evt.target.name]: currentInputErrors,
    }));
    setFieldData((prevState) => ({ ...prevState, [evt.target.name]: value }));
  }, []);

  const updateHandle = async (event) => {
    event.preventDefault();
    try {
      await Authenticator.updateAttributes({
        ...(fieldData?.instagramHandle && {
          instagramHandle: fieldData.instagramHandle,
        }),
        ...(fieldData?.tiktokHandle && {
          tikTokHandle: fieldData.tiktokHandle,
        }),
        ...(fieldData?.twitterHandle && {
          twitterHandle: fieldData.twitterHandle,
        }),
        ...(fieldData?.warpcastHandle && {
          warpcastHandle: fieldData.warpcastHandle,
        }),
      });

      setFieldData((preState) => ({
        ...preState,
        instagramHandle: "",
        tiktokHandle: "",
        twitterHandle: "",
        warpcastHandle: "",
      }));
      notify({
        message: "Social media handle successfully updated!",
        type: "success",
      });
    } catch (error) {
      notify({
        message: "Something went wrong. Please try again later.",
        type: "critical",
      });
    }
  };

  const isHandleButtonDisabled = useMemo(() => {
    const validators = {
      instagramHandle: Validator.checkInstagramHandle,
      twitterHandle: Validator.checkTwitterHandle,
      tiktokHandle: Validator.checkTiktokHandle,
      warpcastHandle: Validator.checkWarpcastHandle,
    };

    return Object.keys(validators).every((key) => {
      const value = fieldData?.[key];
      return !!(value && value !== "")
        ? !!validators[key](value)?.length
        : true;
    });
  }, [fieldData]);

  return (
    <Col xs={12} md={8} className="mx-auto">
      <Row className="handles-wrapper">
        <p>
          <i
            className="icon icon-info"
            style={{ top: "3px", backgroundColor: "rgba(255, 255, 255, .5)" }}
          ></i>
          You can only update handles where no value was provided initially.
        </p>
        {WHITELIST_FIELDS(null)
          .slice(0, 4)
          .map((field, index) => {
            return (
              <Col
                key={field.ariaLabel}
                xxl={3}
                md={6}
                xs={12}
                className="mb-0 mb-xxl-0"
              >
                <InputGroup className="handle">
                  <InputGroup.Text className={field.inputGroupClassName}>
                    <i className={`icon ${field.icon}`} />
                  </InputGroup.Text>
                  <Input
                    customClassName="handle"
                    preventSymbol="@"
                    value={userData?.[field.name]}
                    field={field}
                    onChange={onChange}
                    errors={fieldErrors[field.name]}
                    autoFocus={false}
                    readOnly={userData?.[field.name]}
                  />
                </InputGroup>
              </Col>
            );
          })}
      </Row>
      <Row>
        <Col xs={12}>
          <Button
            variant="primary"
            className="mt-0 mb-4 mx-auto w-100 d-block"
            disabled={isHandleButtonDisabled}
            onClick={(event) => {
              (async () => await updateHandle(event))();
            }}
          >
            Update Social Media Handle
          </Button>
        </Col>
      </Row>

      <WhitelistRequirements
        referralCode={userData?.referralCode}
        className="settings-whitelist-requirements"
      />
    </Col>
  );
};

export default TokenBoost;
