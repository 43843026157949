import React, { useRef } from "react";
import { Accordion, Col } from "react-bootstrap";
import Video from "./Video";
import "./AccountResources.scss";

const AccountResources = () => {
  const videos = [
    { eventKey: "0", title: "What are crypto wallets?", videoId: "w-HDzwS52J0" },
    { eventKey: "1", title: "How to create Coinbase wallet?", videoId: "MxmdGUyF7Zw" },
    { eventKey: "2", title: "How to create Metamask wallet?", videoId: "G_JiU-6dcu8" },
    { eventKey: "3", title: "How to import tokens in Metamask?", videoId: "qxoVJazaVwU" },
    { eventKey: "4", title: "How to sign up on HTX?", videoId: "V9UtUimaT1w" },
    { eventKey: "5", title: "How to sign up on XT.com?", videoId: "qIVeJCCVIfo" },
    { eventKey: "6", title: "How to use VPN?", videoId: "axX8EVtLmiQ" },
  ];

  const videoRefs = useRef({});

  const handleOnExited = (eventKey) => {
    const videoRef = videoRefs.current[eventKey];
    if (videoRef && videoRef.pauseVideo) {
      videoRef.pauseVideo();
    }
  };

  return (
    <Col xs={12} md={8} className="mx-auto">
      <Accordion defaultActiveKey="0" flush>
        {videos.map((video) => (
          <Accordion.Item eventKey={video.eventKey} key={video.eventKey}>
            <Accordion.Header>{video.title}</Accordion.Header>
            <Accordion.Collapse
              eventKey={video.eventKey}
              onExited={() => handleOnExited(video.eventKey)}
            >
              <Accordion.Body>
                <Video
                  ref={(ref) => (videoRefs.current[video.eventKey] = ref)}
                  videoId={video.videoId}
                />
              </Accordion.Body>
            </Accordion.Collapse>
          </Accordion.Item>
        ))}
      </Accordion>
    </Col>
  );
};

export default AccountResources;
